import http from "../http-common";

class SoundService {
  timeConverter(iso) {
    const date = new Date(iso);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }
    return dt + "-" + month + "-" + year;
  }
  async getAllSounds(setRowData) {
    try {
      const response = await http.get("/getMusics");
      const data = response.data.data;
      const tableData = data.map((item) => {
        return {
          id: item.id,
          image: item.imageUrl,
          artist: item.artist,
          title: item.title,
          lyrics: item.lyrics,
          tags: item.tags.join(", "),
          category: item.category,
          createdBy: item.createdBy,
          date: this.timeConverter(item.createdAt),
          movie: item.movie,
          album: item.movie,
          musicDirector: item.musicDirector,
          producer: item.producer,
        };
      });
      return tableData;
    } catch (err) {
      console.log(err);
    }
  }

  deleteSound(id, history, enqueueSnackbar) {
    http
      .delete("/music", {
        data: { id },
      })
      .then((res) => {
        if (res.data.status === 200) {
          enqueueSnackbar("Sound deleted successfully!", {
            variant: "success",
          });
          setTimeout(() => {
            window.location.href = "/all-sounds";
          }, 700);
        } else if (res.data.status === 400 || res.data.status === 401) {
          enqueueSnackbar(res?.data?.message, {
            variant: "error",
          });
        }
      })
      .catch((err) => {
        enqueueSnackbar("Something went wrong!", {
          variant: "error",
        });
      });
  }
}

export default new SoundService();
