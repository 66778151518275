import React, { useEffect, useState } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import Avatar from "@material-ui/core/Avatar";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import soundService from "../services/sound-service";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { useHistory } from "react-router-dom";
import MainLayout from "../Layout/MainLayout";
import { useSnackbar } from "notistack";
import "./AllSounds.css";
import { InputAdornment, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import GetAppIcon from "@material-ui/icons/GetApp";
import EditIcon from "@material-ui/icons/Edit";
import axios from "./../http-common";
import { saveAs } from "file-saver";

export default function AllSounds() {
  const history = useHistory();
  const [rowData, setRowData] = useState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  useEffect(() => {
    (async function anonymousFunction() {
      const allSoundData = await soundService.getAllSounds();
      setRowData(allSoundData);
    })();
  }, []);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const handleDeleteSong = (id) => {
    const confirmBox = window.confirm(
      "Do you really want to delete this song?"
    );
    if (confirmBox === true) {
      soundService.deleteSound(id, history, enqueueSnackbar);
    }
  };
  const handleLyricsDownload = (url, title) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((response) => {
        var blob = new Blob([response.data]);
        saveAs(blob, `${title}.txt`);
      })
      .catch((err) => console.log(err));
  };
  const handlePdfDownload = () => {
    axios
      .get("/pdf", {
        responseType: "blob",
      })
      .then((response) => {
        var blob = new Blob([response.data], {
          type: "application/pdf;charset=utf-8",
        });
        saveAs(
          blob,
          `Pdf_Report_${new Date().toLocaleDateString("en-US")}.pdf`
        );
      })
      .catch((err) => console.log(err));
  };
  const handleSearch = (e) => {
    gridApi.setQuickFilter(e.target.value);
  };
  const DownloadRenderer = (cellParams) => {
    const lyricsUrl = cellParams.data.lyrics;
    const title = cellParams.data.title;
    return (
      <GetAppIcon
        style={{ cursor: "pointer" }}
        onClick={() => handleLyricsDownload(lyricsUrl, title)}
      />
    );
  };
  const ImageCellRenderer = (cellParams) => {
    const image = cellParams.data.image;
    const name = cellParams.data.artist;
    return <Avatar alt={name} src={image} />;
  };
  const ActionsBtnRender = (props) => {
    const id = props.data.id;

    const btnClicked = () => {
      props.clicked(id);
    };
    const editClicked = () => {
      history.push({
        pathname: "/update",
        state: {
          ...props.data,
        },
      });
    };

    return (
      <>
        <EditIcon
          onClick={editClicked}
          style={{ marginRight: "5px", cursor: "pointer" }}
        />
        <DeleteForeverIcon onClick={btnClicked} style={{ cursor: "pointer" }} />
      </>
    );
  };
  return (
    <MainLayout>
      <h2 style={{ width: "16%", margin: "50px auto" }}>
        Singistic All Sounds Table
      </h2>
      <div
        className="ag-theme-alpine"
        style={{ height: "65vh", width: "70%", margin: "2rem auto" }}
      >
        <TextField
          style={{ marginBottom: "10px" }}
          id="input-with-icon-text"
          label="Search"
          variant="outlined"
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <button style={{ marginLeft: "50px" }} onClick={handlePdfDownload}>
          Download PDF Report
        </button>
        <AgGridReact
          pagination={true}
          paginationPageSize={10}
          rowData={rowData}
          frameworkComponents={{
            imageCellRenderer: ImageCellRenderer,
            actionsBtnRender: ActionsBtnRender,
            downloadRenderer: DownloadRenderer,
          }}
          onGridReady={onGridReady}
        >
          <AgGridColumn
            cellRenderer="imageCellRenderer"
            field="image"
          ></AgGridColumn>
          <AgGridColumn field="title" sortable={true}></AgGridColumn>
          <AgGridColumn field="artist" sortable={true}></AgGridColumn>
          <AgGridColumn field="tags" sortable={true}></AgGridColumn>
          <AgGridColumn field="category" sortable={true}></AgGridColumn>
          <AgGridColumn field="date" sortable={true}></AgGridColumn>
          <AgGridColumn field="createdBy"></AgGridColumn>
          <AgGridColumn
            cellRenderer="downloadRenderer"
            headerName="Download"
          ></AgGridColumn>
          <AgGridColumn
            cellRenderer="actionsBtnRender"
            cellRendererParams={{ clicked: handleDeleteSong }}
            headerName="Actions"
          ></AgGridColumn>
        </AgGridReact>
      </div>
    </MainLayout>
  );
}
