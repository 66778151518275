import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "../Login";
import AllSounds from "../Screens/AllSounds";
import Uploader from "../Screens/Uploader";
import PrivateRoute from "./PrivateRoute";
import Update from "./../Screens/Update";

export default function Routes() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Login />
        </Route>
        <PrivateRoute exact path="/upload" Component={Uploader}></PrivateRoute>
        <PrivateRoute
          exact
          path="/all-sounds"
          Component={AllSounds}
        ></PrivateRoute>
        <PrivateRoute exact path="/update" Component={Update}></PrivateRoute>
      </Switch>
    </Router>
  );
}
