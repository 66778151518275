import React from "react";
import { Typography, TextField, Grid, Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Field, Form, Formik, ErrorMessage } from "formik";
import Auth from "./services/auth-service";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) =>
  createStyles({
    input: {
      padding: "5px",
      width: "80%",
      marginBottom: "1rem",
    },
    error: {
      color: "red",
      marginBottom: ".5rem",
    },
  })
);

const FormSchema = Yup.object().shape({
  email: Yup.string().required("User Name is Required"),
  password: Yup.string().required("Password is Required"),
});

function Login() {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  return (
    <div className="container">
      <div className="mg20">
        <Typography variant="h5">Login Form</Typography>
      </div>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={FormSchema}
        validateOnMount
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setTimeout(() => {
            const data = {
              userName: values.email,
              password: values.password,
            };
            Auth.login(data, history, setSubmitting, enqueueSnackbar);
          }, 400);
        }}
      >
        {({
          errors,
          touched,
          handleChange,
          isValid,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
          values,
        }) => (
          <Form>
            <Grid container>
              <Grid item xs={12}>
                <Field
                  className={classes.input}
                  name="email"
                  type="email"
                  label="Email"
                />
                <ErrorMessage
                  className={classes.error}
                  name="email"
                  component="div"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  className={classes.input}
                  name="password"
                  type="password"
                  label="Password"
                />
              </Grid>
              <ErrorMessage
                className={classes.error}
                name="password"
                component="div"
              />
              <Button
                style={{ marginTop: "1rem" }}
                disabled={!isValid || isSubmitting}
                variant="contained"
                color="primary"
                type="submit"
              >
                Submit
                {isSubmitting && (
                  <CircularProgress
                    style={{
                      width: "25px",
                      height: "25px",
                      marginLeft: "10px",
                    }}
                    color="secondary"
                  />
                )}
              </Button>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default Login;
