import S3 from "react-aws-s3";
import { S3Config } from "../config";
import { v4 as uuidv4 } from "uuid";

export async function uploadFilesToS3(file, folderId) {
  return new Promise((resolve, reject) => {
    const ext = file.name.split(".").pop();
    const fileName = generateUniqueFileName(ext);
    let newFileName = `${folderId}/` + fileName;
    const ReactS3Client = new S3(S3Config);
    ReactS3Client.uploadFile(file, newFileName)
      .then((data) => {
        if (data.status === 204) {
          resolve(data);
        } else {
          reject(data);
        }
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
}

const generateUniqueFileName = (ext) => {
  return uuidv4() + "." + ext;
};
