import http from "../http-common";
import { baseUrl, S3Config } from "./../config";
import { uploadFile } from "react-s3";

class UploadFilesService {
  upload(file, onUploadProgress) {
    let formData = new FormData();

    formData.append("file", file);

    return http.post("/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  }

  submitFormData(data, setSubmitting, enqueueSnackbar) {
    http
      .post(`${baseUrl}/addMusic`, data, {
        headers: {
          token: localStorage.getItem("token"),
        },
      })
      .then((res) => {
        setSubmitting(false);
        if (res.data.status === 400 || res.data.status === 401) {
          localStorage.removeItem("token");
          window.location.href = "/";
        } else {
          enqueueSnackbar("File uploaded successfully !", {
            variant: "success",
          });
          setTimeout(() => {
            window.location.href = "/upload";
          }, 700);
        }
      })
      .catch((err) => {
        setSubmitting(false);
        enqueueSnackbar("Something went wrong!", {
          variant: "error",
        });
      });
  }
  updateSongData(data, setSubmitting, enqueueSnackbar, history) {
    http
      .patch("/updateSong", data, {
        headers: {
          token: localStorage.getItem("token"),
        },
      })
      .then((res) => {
        enqueueSnackbar("Updated successfully", {
          variant: "success",
        });
        history.push("/all-sounds");
        setSubmitting(false);
      })
      .catch((err) => {
        setSubmitting(false);
        enqueueSnackbar("Something went wrong!", {
          variant: "error",
        });
      });
  }

  getFiles() {
    return http.get("/files");
  }
}

export default new UploadFilesService();
